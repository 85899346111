import React from "react"
import ViewAnimation from "../../../animation/view-animation"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import {
  StyledContainer,
  StyledImagesList,
  StyledTextContainer,
  StyledTitle,
  StyledImageWrapper,
  StyledPersonName
} from "./index.style"

const PlaceLeasingPerson = ({ content }) => {
  return (
    <StyledContainer>
      <StyledTextContainer>
        <StyledTitle>
          <ViewAnimation transitionDelay>Our Leasing Team</ViewAnimation>
        </StyledTitle>
      </StyledTextContainer>

      <StyledImagesList>
        {content.personsLeasing.map(({ id, name, imageName }) => {
          const image = content?.edges.find(x => x.node.name === imageName)
          return (
            <ViewAnimation>
              <StyledImageWrapper key={id}>
                <GatsbyImage
                  image={getImage(image.node.childImageSharp)}
                  alt={name}
                />
                <StyledPersonName>{name}</StyledPersonName>
              </StyledImageWrapper>
            </ViewAnimation>
          )
        })}
      </StyledImagesList>
    </StyledContainer>
  )
}

export default PlaceLeasingPerson
