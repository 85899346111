import styled from 'styled-components'
import Img from 'gatsby-image'
import { mediaMax } from '../../../../helpers/media-queries'

export const StyledTitle = styled.h3`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #47341e;
  max-width: 300px;
  ${mediaMax('tabletLandscape')} {
    max-width: 100%;
  }
`

export const StyledDescription = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: #47341e;
  max-width: 330px;
  ${mediaMax('tabletLandscape')} {
    max-width: 100%;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  gap: 30px;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 150px;
  ${mediaMax('tabletLandscape')} {
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
`

export const StyledImg = styled(Img)`
  width: calc(100vw - 370px);
  height: 75vh;
  margin-top: 60px;
  ${mediaMax('tabletLandscape')} {
    width: calc(100vw - 40px);
  }
  ${mediaMax('phoneXL')} {
    height: 25vh;
  }
`

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  gap: 30px;
  position: sticky;
  top:0;
  padding-top: 60px;
  ${mediaMax('tabletLandscape')} {
    min-width: auto;
    padding-top: 10px;
    gap: 20px;
  }
`

export const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  padding-top: 10px;
`

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: rgba(71, 52, 30, 0.2);
  &:first-child {
    background: #47341e;
  }
`