import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { mediaMax } from "../../../../helpers/media-queries"
export const StyledTitle = styled.h3`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  font-style: italic;
  text-align: ${({ isMobile }) => isMobile && "center"};
  display: flex;
  align-items: center;
  text-indent: ${({ isMobile }) => !isMobile && "100px"};
  max-width: 720px;
  margin: 50px 50px 50px 30vw;
  color: #47341e;
  ${mediaMax("tabletLandscape")} {
    margin: 50px auto;
  }
`

export const StyledImage = styled(props => <GatsbyImage {...props} />)`
  margin-bottom: 20px;
  width: ${({ $isMobile }) => ($isMobile ? "70%" : "100%")};
`

export const PersonWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? "1fr" : "1fr 1fr 1fr"};
  justify-content: flex-end;
  margin-bottom: 100px;
  width: 100%;
  align-self: ${({ isMobile }) => (isMobile ? "flex-start" : "flex-end")};
`

export const PersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
`

export const PersonName = styled.h4`
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 10px;
  color: #47341e;
`

export const PersonDescription = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: #47341e;
`
