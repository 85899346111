import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import ViewAnimation from "../../../animation/view-animation"
import {
  StyledContainer,
  StyledDescription,
  StyledImg,
  StyledTextContainer,
  StyledTitle,
  Dots,
  Dot,
} from "./index.style"

const Place = ({ content }) => {
  const contentItem = content.map(item => {
    const {
      dataName,
      title,
      description,
    } = item
    const {
      id,
      childImageSharp: { fluid }
    } = item.edges[0].node
    const dotsCount = item.edges.length

    return (
      <StyledContainer key={id}>
        <StyledTextContainer>
          <StyledTitle>
            <ViewAnimation transitionDelay>{title}</ViewAnimation>
          </StyledTitle>
          <StyledDescription>
            <ViewAnimation transitionDelay>{description}</ViewAnimation>
          </StyledDescription>
        </StyledTextContainer>
        <ViewAnimation
          onClick={() =>
            typeof window !== "undefined" &&
            (
              window.sessionStorage.setItem("imageId", id),
              window.sessionStorage.setItem("dataName", dataName)
            )
          }
        >
          <AniLink fade duration={1} to="/amenities-lightbox">
            <StyledImg id={id} fluid={fluid} alt={""} />
          </AniLink>
          {dotsCount > 1 && (
            <Dots>
              {Array.from({ length: dotsCount }, (i) => (
                <Dot key={i} />
              ))}
            </Dots>
          )}
        </ViewAnimation>
      </StyledContainer>
    )
  })

  return <>{contentItem}</>
}

export default Place
