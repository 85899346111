import styled from "styled-components"

import { mediaMax } from "../../../../helpers/media-queries"

export const StyledTitle = styled.h3`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #47341e;
  max-width: 300px;
  ${mediaMax("tabletLandscape")} {
    max-width: 100%;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  gap: 30px;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 150px;
  ${mediaMax("tabletLandscape")} {
    flex-direction: column;
    margin-bottom: 50px;
  }
`

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  gap: 30px;
  position: sticky;
  top: 0;
  padding-top: 60px;
  ${mediaMax("tabletLandscape")} {
    min-width: auto;
    padding-top: 10px;
    gap: 20px;
  }
`

export const StyledImagesList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 10px;
  width: 100%;
  max-width: 70vw;
  ${mediaMax("tabletLandscape")} {
    max-width: 100%;
  }
`

export const StyledImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledPersonName = styled.span`
  padding: 10px 0;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #47341e;
  ${mediaMax("tabletLandscape")} {
    font-size: 17px;
    line-height: 175%;
  }
`
