import React from "react"
import useDeviceDetect from "../../../../hooks/use-device-detect"
import ViewAnimation from "../../../animation/view-animation"
import { getImage } from "gatsby-plugin-image"
import {
  PersonContainer,
  PersonDescription,
  PersonName,
  PersonWrapper,
  StyledImage,
  StyledTitle
} from "./index.style"

const PlacePerson = ({ content }) => {
  const { isMobile } = useDeviceDetect()

  const contentItem = content.persons.map(
    ({ id, name, description, imageName }) => {
      const image = content?.edges.find(x => x.node.name === imageName)
      return (
        <ViewAnimation key={id}>
          <PersonContainer>
            <ViewAnimation>
              <StyledImage
                $isMobile={isMobile}
                image={getImage(image.node.childImageSharp)}
                alt={name}
              />
            </ViewAnimation>
            <PersonName>{name}</PersonName>
            <PersonDescription isMobile={isMobile}>
              {description}
            </PersonDescription>
          </PersonContainer>
        </ViewAnimation>
      )
    }
  )

  return (
    <>
      <ViewAnimation>
        <StyledTitle isMobile={isMobile}>
          Considerate, personalized service that anticipates needs and exceeds
          expectations, carried out by a friendly, experienced staff
        </StyledTitle>
      </ViewAnimation>

      <PersonWrapper isMobile={isMobile}>{contentItem}</PersonWrapper>
    </>
  )
}

export default PlacePerson
