import styled from 'styled-components'

export const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;
  width: 100%;
`

export const ModalWrapper = styled.div`
&.Modal {
  position: absolute;
}

&.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: flex-end mportant;
}
`