import React from "react"
import PageAnimation from "../components/animation/page-animation"
import GlobalStyle from "../helpers/global-styles"
import PageDescription from "../components/page-description"
// import CategoryLeft from "../../components/Categories/category-left"
import PlaceList from "../components/pages/amenities/place-list"
import SmoothScroll from "../helpers/smooth-scroll"
import Layout from "../components/app-layout"
import useDeviceDetect from "../hooks/use-device-detect"
import Promo from "../components/pages/amenities/promo"
import Seo from "../components/seo"

const Amenities = () => {
  const { isMobile } = useDeviceDetect()

  const description =
    "Meditation, recreation, rejuvenation, respite: Ruby offers an abundance of amenities conscientiously created to elevate human experience and facilitate health and connection with nature at every level."

  return (
    <>
      <GlobalStyle />
      <Layout pageName={"Amenities"}>
        <SmoothScroll>
          <PageAnimation>
            {/* <Promo url={'https://vimeo.com/846318596/8c5cd51164'} isFullHeight={!isMobile} /> */}
            <Promo url={'https://vimeo.com/810691315/5ddbcfefe9'} isFullHeight={false} />
            <PageDescription text={description} />
            <PlaceList />
          </PageAnimation>
        </SmoothScroll>
      </Layout>
    </>
  )
}

export default Amenities


export const Head = () => <Seo title="Amenities" />
